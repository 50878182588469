import React, { Fragment } from 'react';
import classNames from 'classnames';

import MainNav from '../components/MainNav';
import SEO from '../components/SEO';

export default function About() {
  return (
    <Fragment>
      <SEO
        title="About"
        description="Meet the team of awesome people helping building the platform"
      />
      <MainNav showLogo title="About" />
      <div className="container py-16">
        <Section title="Product Leads">
          <div className="md:flex">
            <div className="mb-8 md:flex-1 md:pr-8">
              <Profile
                image="https://avatars0.githubusercontent.com/u/7671983?s=460&v=4"
                name="Emma Wedekind"
                role="Founder"
                highlight
              />
            </div>
            <div className="mb-8 md:flex-1 md:pr-8">
              <Profile
                image="https://avatars0.githubusercontent.com/u/219207?s=460&v=4"
                name="Crysfel Villa"
                role="Backend Lead"
                highlight
              />
            </div>
            <div className="mb-8 md:flex-1">
              <Profile
                image="https://avatars3.githubusercontent.com/u/3723951?s=460&v=4"
                name="Mosh Feuchtwanger"
                role="Frontend Lead"
                highlight
              />
            </div>
          </div>
        </Section>
        <Section title="Core Contributors">
          <Profile
            image="https://s.gravatar.com/avatar/2a2d22c34d297073e37687d3a846de47?s=460"
            name="Brent M Clark"
            className="inline-block w-32 md:mr-4"
          />
          <Profile
            image="https://avatars0.githubusercontent.com/u/390423?s=460&v=4"
            name="Jagadish K."
            className="inline-block w-32 md:mr-4"
          />
        </Section>
      </div>
    </Fragment>
  );
}

function Profile({ name, role, image, highlight, className }) {
  const css = classNames({
      'p-4 bg-primary-lighter md:p-6': highlight,
    },
    className
  );
  const titleCss = classNames('font-display', {
    'text-2xl mt-2': highlight,
    'text-lg mt-2': !highlight,
  });

  return (
    <div className={css}>
      <img src={image} />
      <h3 className={titleCss}>{name}</h3>
      <p>{role}</p>
    </div>
  );
}

function Section({ children, title }) {
  return (
    <section className="mb-20">
      <h2 className="text-4xl font-display font-normal uppercase leading-tight mb-12 border-b border-secondary-lightest text-secondary-dark">
        {title}
      </h2>
      {children}
    </section>
  );
}
